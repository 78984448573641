import { SessionState, InitStatusType, Status } from './types.d';

export const reducers = {
  setInitStatus(state: SessionState, initStatus: InitStatusType) {
    return {
      ...state,
      initStatus,
    };
  },
  setStatus(state: SessionState, status: Status) {
    return {
      ...state,
      status,
    };
  },
  setUser(state: SessionState, user: any | null) {
    return {
      ...state,
      user,
    };
  },
};
