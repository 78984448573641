import { RootModel } from '../index';
import { RematchDispatch, RematchRootState } from '@rematch/core';
import {
  makeLogin,
  getStatus,
  makeLogout,
  getAuthenticatedUserData,
  updateAuthenticatedUserData,
} from '@services/auth';
import { InitStatusType } from './types.d';

export const effects = (dispatch: RematchDispatch<RootModel>) => ({
  async getStatus(
    payload?: { getUserData: boolean },
    rootState?: RematchRootState<RootModel, Record<string, never>>,
  ): Promise<void> {
    if (rootState?.Session.initStatus !== InitStatusType.initializing) {
      dispatch.Session.setInitStatus(InitStatusType.loading);
    }
    const {
      data: { data, success },
    } = await getStatus();

    dispatch.Session.setStatus(success ? data : null);
    if (success && payload?.getUserData) {
      await dispatch.Session.getUser();
    }
    dispatch.Session.setInitStatus(InitStatusType.ready);
  },

  async updateAuthenticatedUserData(payload: any): Promise<boolean> {
    const {
      data: { data, success },
    } = await updateAuthenticatedUserData(payload);

    if (success) {
      dispatch.Session.setUser(data);
      return true;
    }
    return false;
  },

  async getUser(): Promise<void> {
    const {
      data: { data, success },
    } = await getAuthenticatedUserData();

    if (success) {
      await dispatch.Session.setUser(data);
      return;
    }

    dispatch.Session.setUser(null);
  },

  async makeLogin(
    payload: FirstArgument<typeof makeLogin>,
    // rootState: RematchRootState<RootModel, Record<string, never>>,
  ): Promise<[boolean, string]> {
    const {
      data: { data, success, message },
    } = await makeLogin(payload);

    if (success) {
      dispatch.Session.setUser(data);
      await dispatch.Session.getStatus();
      return [true, 'Login realizado com sucesso'];
    }

    return [false, message];
  },

  async makeLogout(): Promise<boolean> {
    const {
      data: { success },
    } = await makeLogout();

    if (success) {
      dispatch.Session.setUser(null);
      dispatch.Session.setStatus(null);
      return true;
    }

    return false;
  },
});
