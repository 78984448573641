import { createModel } from '@rematch/core';
import { RootModel } from '../index';

export const Customize = createModel<RootModel>()({
  state: {
    theme: 'light', // light - dark
    contentWidth: 'full', // full - boxed
    sidebarCollapsed: false,
    sidebarCollapseButton: true,
    layout: 'VerticalLayout', // VerticalLayout - HorizontalLayout
    navigationFull: false,
    navigationBg: false,
    direction: 'ltr', // ltr - rtl
  },
});
