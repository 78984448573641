import { createModel } from '@rematch/core';
import { RootModel } from '../index';

const { version } = require('../../../../package.json');

export const App = createModel<RootModel>()({
  state: {
    version,
  },
});
