import { Models, RematchDispatch, RematchRootState, ModelEffects } from '@rematch/core';

import { count } from './count';
import { App } from './App';
import { Customize } from './Customize';
import { contact } from './contact';
import { calendar } from './calendar';
import { Session } from './Session';

export interface RootModel extends Models<RootModel> {
  count: typeof count;
  contact: typeof contact;
  calendar: typeof calendar;
  App: typeof App;
  Customize: typeof Customize;
  Session: typeof Session;
}
export const models: RootModel = { count, contact, calendar, App, Customize, Session };
