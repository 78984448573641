import type { AppProps } from 'next/app';
import '../../public/vendor-theme/icons/remixicon.css';
require('../style/vendor-theme/less/yoda-theme.less');

import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // import plugin
import isToday from 'dayjs/plugin/isToday'; // import plugin
import isTomorrow from 'dayjs/plugin/isTomorrow'; // import plugin
import relativeTime from 'dayjs/plugin/relativeTime'; // import plugin
import timezone from 'dayjs/plugin/timezone'; // import plugin
import localeData from 'dayjs/plugin/localeData'; // import plugin
import weekday from 'dayjs/plugin/weekday'; // import plugin
import ptBR from 'antd/lib/locale/pt_BR';

dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.tz.guess();
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

import { Provider, connect } from 'react-redux';
import { RootState, Dispatch, store } from '@store';
import { ConfigProvider } from 'antd';

function App({ Component, pageProps }: AppProps) {
  return (
    <Provider store={store}>
      <ConfigProvider locale={ptBR}>
        <Component {...pageProps} />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
