import Router from 'next/router';

export function getWindowLocationParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

/**
 * Trata o direcionamento do usuário para URL de autenticação.
 *
 * Se o parâmetro back for enviado como "true", antes do redirecionamento, obtem a URL atual e adicionado junto na URL de autenticação.
 * Assim quando autenticado, o usuário será retrocedido para a URL indicada.
 *
 * Também é possível passar o parâmetro "backTo" o valor forçado já para o recirecionamento
 *
 * @param back Boolean
 * @param backTo String
 *
 * @returns void
 */
export function redirectToLogin({ back = true, backTo = '' } = {}) {
  const loginRoute = '/auth/login';
  let url = loginRoute;
  let actualRoute = Router?.router?.asPath || '';

  if (actualRoute?.indexOf('[') > -1) {
    actualRoute = Router?.router?._inFlightRoute || '';
  }

  if (back && actualRoute !== url) {
    const queryBackTo = !!backTo
      ? backTo
      : actualRoute +
        (() => {
          const queryParams = getWindowLocationParams();
          const queryParamsKeys = Object.keys(queryParams);
          return queryParamsKeys?.length === 0
            ? ''
            : `?${queryParamsKeys
                .filter((key) => !!queryParams[key])
                .map((key) => `${key}=${queryParams[key] || ''}`)
                .join('&')}${window.location.hash}`;
        })();

    url = !!queryBackTo ? `${url}?backTo=${encodeURIComponent(queryBackTo)}` : url;
  }

  if (actualRoute?.indexOf(loginRoute) !== 0) {
    Router.push(url);
  }
}

/**
 * Utilizado para validar o redirecionamento do usuário, verificando se existe o parâmetro "backTo" na URL.
 * Caso exista, redireciona o usuário para a URL indicada.
 * Caso contrário, redireciona o usuário para a URL enviada como parâmetro
 *
 * @param url String
 * @returns void
 */
export function validateAndRedirectTo(url: string) {
  const a = new URL(window.location.href);
  const backTo = a.searchParams.get('backTo');

  if (backTo) {
    Router.push(decodeURIComponent(backTo as string));
    return;
  }

  Router.push(url);
}
