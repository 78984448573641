import { ApiRequest } from '../request';
import { AxiosPromise } from 'axios';

export const makeLogin = (data: any): AxiosPromise => {
  return ApiRequest.post('/login', data);
};

export const getStatus = (): AxiosPromise => {
  return ApiRequest.get('/status');
};

export const getAuthenticatedUserData = (): AxiosPromise => {
  return ApiRequest.get('/me');
};

export const makeLogout = (): AxiosPromise => {
  return ApiRequest.post('/logout');
};

export const updateAuthenticatedUserData = (data: any): AxiosPromise => {
  return ApiRequest.patch('/me', data);
};
