import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
const { version } = require('../../package.json');
import { redirectToLogin } from '@helpers/router';
import { message } from 'antd';

export const CreateInstance = (options: AxiosRequestConfig): AxiosInstance => {
  axios.defaults.withCredentials = true;
  const instance = axios.create({
    ...options,
    headers: { 'X-App-version': version },
  });

  // instance.interceptors.response.use(
  //   function (response) {
  //     debugger;

  //     // Any status code that lie within the range of 2xx cause this function to trigger
  //     // Do something with response data
  //     return response;
  //   },
  //   function (error) {
  //     debugger;
  //     // Any status codes that falls outside the range of 2xx cause this function to trigger
  //     // Do something with response error
  //     return Promise.reject(error);
  //   },
  // );

  return instance;
};

const ApiRequest = CreateInstance({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 60000,
  withCredentials: true,
  validateStatus: function (status) {
    return status < 500; // Resolve only if the status code is less than 500
  },
});

const getRefreshToken = () =>
  fetch(`${process.env.NEXT_PUBLIC_API_URL}/auth/refresh`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    redirect: 'follow',
    referrer: 'no-referrer',
  });

// implements refresh token
ApiRequest.interceptors.response.use((res) => {
  //@ts-ignore
  if (res.status === 403 && res.config && !res.config.__isRetryRequest) {
    return new Promise((resolve, reject) => {
      getRefreshToken()
        .then(() => {
          //@ts-ignore
          res.config.__isRetryRequest = true;
          resolve(ApiRequest(res.config));
        })
        .catch((error) => {
          console.log('Refresh login error: ', error);
          reject(error);
        });
    });
  }

  if (res?.status === 401) {
    if (window.location.pathname.indexOf('/auth') === -1) {
      message.error('Sua sessão expirou. Por favor faça login novamente.');
    }
    redirectToLogin();
    return res;
  }

  return res;
});

export { ApiRequest };
