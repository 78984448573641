// import ResponseAuthStatus from '@api/feature-module/auth/responseStatus.interface';
// import { PublicUserData } from '@api/feature-module/users/user.helpers';

type ResponseAuthStatus = any;
type PublicUserData = any;

type Status = null | ResponseAuthStatus;
export enum InitStatusType {
  initializing = 'initializing',
  loading = 'loading',
  ready = 'ready',
}

type SessionState = {
  initStatus: InitStatusType;
  status: Status;
  user: null | PublicUserData;
};
