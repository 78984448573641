import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import { effects } from './effects';
import { reducers } from './reducers';
import { SessionState, InitStatusType } from './types.d';

export const Session = createModel<RootModel>()({
  state: <SessionState>{
    user: null,
    status: null,
    initStatus: InitStatusType.initializing,
  },
  reducers,
  effects,
});
